import styled, {keyframes} from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const HeroContainer = styled.div`
  /* background: #0c0c0c; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 870px;
  position: relative;
`;
export const bannerHeader1 = styled.div`
  width: 100%;

  justify-content: center;
  align-items: center;
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
  }
`;
export const bannerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
export const Bg = styled.div`
  text-align: center;
  background-image: url("firstchoice.jpg");
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  height: 844px;
  background-position: center;
  @media screen and (max-width: 768px) {
    /* width:20%;
    height: 20%; */
    /* background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center; */
    /* background-attachment: fixed; */
    /* max-width: 10px; */
    /* display: inline-block; */
    /* width: 100%; */
    background-repeat: no-repeat;
    vertical-align: middle;
    background-size: cover;
    background-position: center;
    height: 844px;
  }
  @media screen and (max-width: 1024px) {
    /* width:20%;
    height: 20%; */
    /* background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center; */
    /* background-attachment: fixed; */
    /* max-width: 10px; */
    /* display: inline-block; */
    /* width: 100%; */
    background-repeat: no-repeat;
    vertical-align: middle;
    background-size: cover;
    background-position: center;
    height: 555px;
  }
  @media screen and (min-width: 1440px) {
    /* width:20%;
    height: 20%; */
    /* background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center; */
    /* background-attachment: fixed; */
    /* max-width: 10px; */
    /* display: inline-block; */
    /* width: 100%; */
    background-repeat: no-repeat;
    vertical-align: middle;
    background-size: cover;
    background-position: center;
    height: 786px;
  }
  @media screen and (max-width: 420px) {
    /* width:20%;
    height: 20%; */
    /* background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center; */
    /* background-attachment: fixed; */
    /* max-width: 10px; */
    /* display: inline-block; */
    /* width: 100%; */
    background-repeat: no-repeat;
    vertical-align: middle;
    background-size: cover;
    background-position: center;
    height: 846px;
  }
`;

export const MarginDiv = styled.div`
  margin-top: 5%;
  @media screen and (max-width: 768px) {
    margin-top: 15%;
  }
`;
export const MintContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 0.6rem;
  /* height: 15vh; */
  @media screen and (max-width: 768px) {
    width: 100%;

  }
`;
export const NavBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12vh;
  @media screen and (max-width: 768px) {
    /* height: 10vh; */

  }
`;

export const MintBtn = styled.a`
  width: 32%;
  border-radius: 50px;
  background: rgba(0, 0, 0, 1);
  white-space: nowrap;
  padding: 10px 22px;
  margin-top: 7px;
  font-family: 'Raleway', sans-serif;
  color: white;
  // padding: 0 1rem;
  font-size: 28px;
  outline: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    // transition: all 0.2s ease-in-out;
    // background: #fff;
    // color: black;
    color: black;
    background: #86f705;
    box-shadow: 0 0 10px #86f705, 0 0 40px #86f705, 0 0 80px #86f705;
    
  }
  @media screen and (max-width: 768px) {
    font-size: 19px;
    width: 52%;
    /* margin-left: -1px; */
  }
  @media screen and (max-width: 1024px) {
    // font-size: 19px;
    width: 52%;
    /* margin-left: -1px; */
  }
`;
export const BtnCW = styled.a`
  width: auto;
  border-radius: 50px;
  background: rgba(0, 0, 0, 1);
  white-space: nowrap;
  padding: 10px 22px;
  color: white;
  font-size: 28px;
  outline: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: black;
  }
  @media screen and (max-width: 768px) {
    font-size: 19px;
    width: 100%;

  }
`;
export const InputMint = styled.input`
  width: 100%;
  pointer-events: none;

  text-align: center;
  border-radius: 20px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  background: #000000;
  white-space: nowrap;
  /* padding: 10px 22px; */
  padding-right: auto;
  padding-left: 2vh;
  color: white;
  font-size: 45px;
  font-family: 'Raleway', sans-serif;
  outline: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
 
  @media screen and (max-width: 768px) {
    padding: 0.2rem;
    width: 100%;
  }
  @media screen and (max-width: 1024px) {
    padding: 0rem;
    width: 100%;
    margin-bottom: 0.8rem;
  }
  @media screen and (min-width: 1440px) {
    padding: 1rem;
    width: 100%;
  }
`;
export const NavBtnLink1 = styled.button`
  width: 100%;
  border-radius: 20px;
  background: rgba(0, 0, 0, 1);
  white-space: nowrap;
  padding: 10px 22px;
  color: white;
  font-size: 28px;
  outline: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: black;
  }
  @media screen and (max-width: 768px) {
    font-size: 19px;
    width: 100%;
  }
`;



// export const NavBtn = styled.nav`
//   display: flex;
//   align-items: center;

//   @media screen and (max-width: 768px) {
//     display: none;
//   }
// `;

// export const NavBtnLink = styled.button`
//   border-radius: 50px;
//   background: white;
//   white-space: nowrap;
//   width: 70%;
//   height: 12vh;
//   padding: 10px 22px;
//   color: black;
//   font-size: 27px;
//   outline: none;
//   border: none;
//   cursor: pointer;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #fff;
//     //color: #010606;
//   }
// `;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;



export const VideoBg = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("bg.jpeg");
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;
export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

// Animations of thunder and rain

const Raini = keyframes`
0%{
  background-position: 0% 0%;
}
100%{
  background-position: 20% 100%;
}
`
const Lightining = keyframes`
0%{
  opacity: 0;
}
20%{
  opacity: 0;
}
21%{
  opacity: 1;
}
25%{
  opacity: 0;
}
30%{
  opacity: 0;
}
31%{
  opacity: 1;
}
35%{
  opacity: 0;
}
100%{
  opacity: 0;
}`;

export const Rain = styled.div`
    height: 100vh;
    background-image: url(rain.png);
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    animation: ${Raini} 0.3s linear infinite;
    &:before{
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(lightining.png);
      top: -100px;
      transform: rotate(180deg);
      animation: ${Lightining} 4s linear infinite;
      opacity: 1;
    }
`;

export const WholeDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
  margin-top:4rem;
  border-bottom: 1rem solid;
  border-image-slice: 1;
  border-width: 7px;
  bottom:0;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  @media screen and (max-width: 768px) {
   border: none;
   margin-top: 5rem;

  }
  @media screen and (min-width: 1440px) {
   
    margin-top: 10.5rem;
 
   }
   @media screen and (max-width: 1024px) {
   
    margin-top:0rem;
 
   }
`;



export const MegaDiv = styled.div`
background-color: rgba(0,0,0,.5); 
padding: 1rem;
border:  thick double #86f705;
border-radius: 2rem;
&:hover {
 
  box-shadow: 0 0 10px #86f705, 0 0 40px #86f705, 0 0 80px #86f705;
  
}




@media screen and (min-width: 1440px) {
  margin-bottom: 9rem;
 }
 @media screen and (min-width: 1024px) {
  margin-bottom: 5rem;
 }
 @media screen and (max-width: 420px) {
  margin-top: 14rem;
  margin-bottom: 7rem;
 }
 
`;




