import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SoicalMediaIcon,
  // SidebarLink,
} from "./SidebarElements";
// import Discord from '../../images/Opensea.png';

import { FaTwitter, FaDiscord } from "react-icons/fa";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          {/* <SidebarLink
            to="about"
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            About
          </SidebarLink>

          <SidebarLink
            to="story"
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Roadmap
          </SidebarLink>

          <SidebarLink
            to="faq"
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            FAQ's
          </SidebarLink>
          <SidebarLink
            to="services"
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Team
          </SidebarLink> */}
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div className="side-bar-menu">
              <SoicalMediaIcon
                href="https://opensea.io/collection/jaguar-joe-eth"
                target="_blank"
              >
                <img
                  src={
                    "https://storage.googleapis.com/opensea-static/Logomark/Logomark-Transparent%20White.svg"
                  }
                  style={{
                    width: 43,
                    height: 43,
                    marginTop: -20,
                    color: "black",
                  }}
                  alt={"Opensea"}
                />
              </SoicalMediaIcon>
              <SoicalMediaIcon
                href="https://discord.gg/c8FwpmV3JM"
                target="_blank"
              >
                <FaDiscord />
              </SoicalMediaIcon>

              <SoicalMediaIcon
                href="https://twitter.com/OnejungleOrg"
                target="_blank"
              >
                <FaTwitter />
              </SoicalMediaIcon>
              {/* <SoicalMediaIcon
                // href="https://www.instagram.com/cheekylionclub/?hl=en"
                target="_blank"
              >
                <FaInstagram />
              </SoicalMediaIcon> */}
            </div>
          </div>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
